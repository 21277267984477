import { AspectRatio, Box, Grid, Title } from "@mantine/core";
import Image from "next/image";
import Router from "next/router";
import React from "react";

import PurpleToBlueButton from "../../components/Button/PurpleToBlueButton";
import { PAGE_ROUTES } from "../../constants/Navigation";
import Logo from "../Logo/Logo";

function HeroIntro() {
  return (
    <Grid>
      <Grid.Col
        xs={12}
        sm={12}
        md={6}
        sx={{ textAlign: "center", height: "100%", my: 2 }}
      >
        <Box>
          <Title
            order={2}
            weight={300}
            px="md"
            py="sm"
            ta="left"
            size="3.75rem"
            style={{ display: "inline-block" }}
          >
            <span>
              Here to help you remember all the little things
              <Logo
                logoHeight={50}
                style={{
                  display: "inline-block",
                  marginBottom: "12px",
                  marginLeft: "8px",
                }}
              />
            </span>
          </Title>
        </Box>
        <Title order={5} weight={400} px="md" py="sm" ta="left" size="1.5rem">
          Quickly capture moments as easily as texting a friend, get a daily
          text with a special moment from your little one&apos;s past so they
          never grow up too fast.
        </Title>
        <PurpleToBlueButton
          mt="md"
          size="lg"
          onClick={() => {
            Router.push({
              pathname: PAGE_ROUTES.DASHBOARD,
              query: {
                ...Router.query,
                new: 1,
              },
            });
          }}
          onClickSendEvent={{
            category: "Landing",
            action: "GetStarted",
            label: "HeroIntro",
          }}
        >
          START SAVING NOW!
        </PurpleToBlueButton>
      </Grid.Col>

      <Grid.Col mt="xl" xs={12} sm={12} md={6}>
        <AspectRatio
          ratio={552 / 647}
          maw={552}
          mx="auto"
          sx={{
            position: "relative",
          }}
        >
          <Image
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            style={{ objectFit: "contain" }}
            alt="example of messages between user and MiniMemories"
            src={"/landing/hero-phones.png"}
            priority
          />
        </AspectRatio>
      </Grid.Col>
    </Grid>
  );
}

export default HeroIntro;
