import { Box, useMantineTheme } from "@mantine/core";
import { IconBrandApple } from "@tabler/icons-react";
import React from "react";

import Device from "../Device/Device";
export default function IMessageLandingBanner() {
  const theme = useMantineTheme();
  return (
    <Device>
      {({ isIOS, isMacOs }) => {
        if (isIOS || isMacOs) {
          return (
            <Box
              ta="center"
              p="sm"
              fw={300}
              fz="16px"
              sx={{
                backgroundColor: theme.colors.grape[7],
                color: theme.colors.gray[0],
              }}
            >
              Save those precious moments using <b>iMessage</b>{" "}
              <IconBrandApple size={18} style={{ margin: "-3px 0px" }} />. As
              easy as texting a friend! ⚡
            </Box>
          );
        }
      }}
    </Device>
  );
}
