import { AspectRatio, Grid, Title } from "@mantine/core";
import Image from "next/image";
import Router from "next/router";
import React from "react";

import PurpleToBlueButton from "../../components/Button/PurpleToBlueButton";
import { PAGE_ROUTES } from "../../constants/Navigation";

function TimelineExample() {
  return (
    <Grid
      sx={{
        mt: 10,
      }}
    >
      <Grid.Col
        xs={12}
        sm={12}
        md={6}
        sx={{ textAlign: "center", height: "100%", mb: 4 }}
      >
        <Title order={2} weight={300} px="md" py="sm" ta="left" size="3.75rem">
          Your Timeline.
        </Title>
        <Title order={5} weight={400} px="md" py="sm" ta="left" size="1.5rem">
          All submissions are securly saved, with the date and your child&apos;s
          age so you can easily scroll through your precious memories! This
          unique timeline of your child&apos;s life will capture not only the
          big moments but all the mini memories that make life with them
          special.
        </Title>
        <PurpleToBlueButton
          mt="md"
          size="lg"
          onClick={() =>
            Router.push({
              pathname: PAGE_ROUTES.DASHBOARD,
              query: {
                ...Router.query,
                new: 1,
              },
            })
          }
          onClickSendEvent={{
            category: "Landing",
            action: "GetStarted",
            label: "TimelineExample",
          }}
        >
          GET STARTED
        </PurpleToBlueButton>
      </Grid.Col>
      <Grid.Col
        mt="xl"
        xs={12}
        sm={12}
        md={6}
        sx={{ textAlign: "center", height: "100%" }}
      >
        <AspectRatio
          ratio={552 / 528}
          maw={552}
          mx="auto"
          sx={{
            position: "relative",
          }}
        >
          <Image
            fill
            style={{ objectFit: "contain" }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            alt="example of messages between user and MiniMemories"
            src={"/mock/timeline3.png"}
          />
        </AspectRatio>
      </Grid.Col>
    </Grid>
  );
}

export default TimelineExample;
