import { IconHome, IconLogin } from "@tabler/icons-react";
import Router from "next/router";
import React from "react";

import { PAGE_ROUTES } from "../../constants/Navigation";
import Button from "../Button/Button";
import useCurrentUser from "../hooks/useCurrentUser";

function GoToDashboardButton() {
  const [currentUser] = useCurrentUser();

  const buttonText = currentUser ? "Lets Go!" : "Login";
  return (
    <Button
      onClickSendEvent={{
        category: "Landing",
        action: "GoToDashboard",
        label: buttonText,
      }}
      onClick={() => Router.push(PAGE_ROUTES.DASHBOARD)}
      sx={{
        float: "right",
      }}
      leftIcon={currentUser ? <IconHome /> : <IconLogin />}
      variant="outline"
    >
      {buttonText}
    </Button>
  );
}

export default GoToDashboardButton;
