import { Box, Grid, Text, Title } from "@mantine/core";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SearchIcon from "@mui/icons-material/Search";
import TimelineIcon from "@mui/icons-material/Timeline";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import React from "react";

import RaisedCard from "../RaisedCard/RaisedCard";
import PillDivider from "./PillDivider";

// const whatsIncludedCardStyle = {
//   // p: 3,
//   textAlign: "center",
//   height: "100%",
//   // https://cssgradient.io/
//   //background:
//   //  "linear-gradient(0deg, rgba(8,241,255,0.7147233893557423) 0%, rgba(188,0,255,0.5494572829131652) 100%)",
// };
const whatsIncludedIconStyle = {
  fontSize: "70px",
  width: "100%",
  //my: 2,
  //color: "purple",
};

function WhatsIncluded() {
  return (
    <Grid my="md" maw={700} mx="auto">
      <Grid.Col xs={12} sx={{ textAlign: "center", p: 2 }}>
        <Title order={5} px="md" py="xs" ta="center" fw={400} size="2.125rem">
          What&apos;s Included
        </Title>
        <Title order={6} px="md" py="xs" ta="center" fw={400} size="1.25rem">
          A feature set that is small but mighty, just like your little one 😉
        </Title>

        <br />
        <PillDivider />
      </Grid.Col>
      <Grid.Col xs={12}>
        <svg width={0} height={0}>
          <linearGradient id="whatsIncludedDaily" x1={1} y1={0} x2={1} y2={1}>
            <stop offset={0} stopColor="#ff9619" />
            <stop offset={1} stopColor="#ff0000" />
          </linearGradient>
        </svg>
        <svg width={0} height={0}>
          <linearGradient id="whatsIncludedPhone" x1={1} y1={0} x2={1} y2={1}>
            <stop offset={0} stopColor="#c419ff" />
            <stop offset={1} stopColor="#7183e9" />
          </linearGradient>
        </svg>
        <svg width={0} height={0}>
          <linearGradient
            id="whatsIncludedTimeline"
            x1={1}
            y1={0}
            x2={1}
            y2={1}
          >
            <stop offset={0} stopColor="#1997ff" />
            <stop offset={1} stopColor="#54f579" />
          </linearGradient>
        </svg>
        <Grid grow>
          {/* Daily Memories */}
          <Grid.Col xs={12} sm={12} md={5}>
            <RaisedCard
              sx={{ textAlign: "center", height: "100%" }}
              maw={300}
              mx="auto"
            >
              <Box pt="sm">
                <WbSunnyIcon
                  color="secondary"
                  sx={{
                    ...whatsIncludedIconStyle,
                    fill: "url(#whatsIncludedDaily)",
                  }}
                />
              </Box>
              <Title mt="sm" order={5} fw={400} size="1.5rem">
                Daily Memories
              </Title>
              <Text mt="sm">Receive a past memory each day</Text>
            </RaisedCard>
          </Grid.Col>
          {/* Unique Phone Numbers */}
          <Grid.Col xs={12} sm={12} md={5}>
            <RaisedCard
              sx={{ textAlign: "center", height: "100%" }}
              maw={300}
              mx="auto"
            >
              <Box mt="sm">
                <PhoneIphoneIcon
                  color="secondary"
                  sx={{
                    ...whatsIncludedIconStyle,
                    fill: "url(#whatsIncludedPhone)",
                  }}
                />
              </Box>
              <Title mt="sm" order={5} fw={400} size="1.5rem">
                Unique Phone Numbers
              </Title>
              <Text mt="sm">
                Each child will be assigned a unique and consistent number that
                you can save to your phone for easy access
              </Text>
            </RaisedCard>
          </Grid.Col>
          {/* Memory timeline */}
          <Grid.Col xs={12} sm={12} md={5}>
            <RaisedCard
              sx={{ textAlign: "center", height: "100%" }}
              maw={300}
              mx="auto"
            >
              <Box pt="sm">
                <TimelineIcon
                  color="secondary"
                  sx={{
                    ...whatsIncludedIconStyle,
                    fill: "url(#whatsIncludedTimeline)",
                  }}
                />
              </Box>
              <Title mt="sm" order={5} fw={400} size="1.5rem">
                Memory timeline
              </Title>

              <Text mt="sm">
                See all your memories in one easy to nagivate and interactive
                place
              </Text>
            </RaisedCard>
          </Grid.Col>
          {/* Powerful Search */}
          <Grid.Col xs={12} sm={12} md={5}>
            <RaisedCard
              sx={{ textAlign: "center", height: "100%" }}
              maw={300}
              mx="auto"
            >
              <Box pt="sm">
                <SearchIcon
                  color="secondary"
                  sx={{
                    ...whatsIncludedIconStyle,
                    fill: "url(#whatsIncludedDaily)",
                  }}
                />
              </Box>
              <Title mt="sm" order={5} fw={400} size="1.5rem">
                Powerful Search
              </Title>

              <Text mt="sm">Effortlessly find any cherished memory</Text>
            </RaisedCard>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

export default WhatsIncluded;
