import {
  Card as MantineCard,
  CardProps as MantineCardProps,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";

export interface RaisedCardProps extends MantineCardProps {
  noElevation?: boolean;
}

function RaisedCard({
  noElevation = false,
  sx = {},
  ...others
}: RaisedCardProps) {
  const useElevation = useMediaQuery("(min-width:600px)") && !noElevation;

  return (
    <MantineCard
      shadow={useElevation ? "sm" : null}
      p="xl"
      radius="lg"
      withBorder
      sx={{
        height: "100%",
        overflow: "hidden",
        ...sx,
      }}
      {...others}
    />
  );
}

export default RaisedCard;
