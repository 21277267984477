import { Box, Container, Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";

import useFeatureFlag from "../components/hooks/useFeatureFlag";
import IMessageLandingBanner from "../components/IMessageLandingPromos/IMessageLandingBanner";
import GoToDashboardButton from "../components/Landing/GoToDashboardButton";
import HeroIntro from "../components/Landing/HeroIntro";
import HowItWorks from "../components/Landing/HowItWorks";
import TimelineExample from "../components/Landing/TimelineExample";
import WhatsIncluded from "../components/Landing/WhatsIncluded";
import LogoWithName from "../components/Logo/LogoWithName";
import LogoWithShortName from "../components/Logo/LogoWithShortName";
import Page from "../components/Page/Page";

function Landing() {
  const landingPageVersion = useFeatureFlag({
    flagName: "landing-page-variation",
    defaultValue: "v1",
  });

  const useLargeLogo = useMediaQuery("(min-width:500px)");

  return (
    <>
      <IMessageLandingBanner />
      <Page
        pageName="MiniMemories"
        fullScreen={true}
        allowUnauthenticated={true}
      >
        <Container>
          {/* top bar */}
          <Grid>
            <Grid.Col span={6}>
              <Container>
                {useLargeLogo ? (
                  <LogoWithName align="left" />
                ) : (
                  <LogoWithShortName align="left" />
                )}
              </Container>
            </Grid.Col>

            <Grid.Col span={6}>
              <GoToDashboardButton />
            </Grid.Col>
          </Grid>

          {/* body items */}
          {landingPageVersion === "v1" && (
            <>
              <HeroIntro />
              <HowItWorks />
              <WhatsIncluded />
              <TimelineExample />
              {/* Remoiving pricing to see if that makes a difference in 
              the number of clicks on get started */}
              {/* <Pricing /> */}
            </>
          )}

          {/* spacer */}
          <Box py="xl" />
        </Container>
      </Page>
    </>
  );
}

export default Landing;
