import React from "react";

import Button from "./Button";

function PurpleToBlueButton({ ...others }) {
  return (
    <Button
      variant="gradient"
      gradient={{ from: "#c419ff", to: "#7183e9", deg: 105 }}
      fw={500}
      {...others}
    />
  );
}

export default PurpleToBlueButton;
