import { Box, Grid, MediaQuery, Title } from "@mantine/core";
import Image from "next/image";
import React from "react";

import PillDivider from "./PillDivider";

function HowItWorks() {
  return (
    <Grid
      sx={{
        my: 4,
      }}
    >
      <Grid.Col
        xs={12}
        sm={12}
        md={12}
        sx={{ textAlign: "center", height: "100%" }}
      >
        <Title order={2} px="md" py="xs" ta="center" fw={400} size="3.75rem">
          How It Works
        </Title>
        <Title order={2} px="md" py="xs" ta="center" fw={400} size="3.75rem">
          📱 💬 😍
        </Title>
        <br />
        <PillDivider />
      </Grid.Col>

      {/* DESIGN FOR LARGE FORMAT */}
      <MediaQuery smallerThan="md" styles={{ display: "none" }}>
        <Grid.Col
          xs={12}
          sx={{
            textAlign: "center",
            height: "100%",
          }}
        >
          <Grid
            sx={{
              my: 4,
            }}
          >
            {/* 1 */}
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              sx={{ height: "320px", position: "relative" }}
            >
              <Box
                sx={{
                  textAlign: "right",
                  margin: 0,
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  //"-ms-transform": "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <Title
                  order={5}
                  fw={400}
                  px="md"
                  size="1.5rem"
                  sx={{
                    maxWidth: "350px",
                  }}
                >
                  📱 Text the special phone number we provide for each child
                  including pictures and emoji ❤️. Just as easy as texting a
                  friend!
                </Title>
              </Box>
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              sx={{
                textAlign: "left",
              }}
            >
              <Image
                alt=""
                width="330"
                height="335"
                src={"/landing/how-it-works1.png"}
              />
              <Title order={5} fw={600} size="1rem" color="blue" pl="30px">
                We also support Apple iMessage! 🎉
              </Title>
            </Grid.Col>
            {/* 2 */}
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              sx={{
                textAlign: "right",
              }}
            >
              <Image
                alt=""
                width="300"
                height="508"
                src={"/landing/how-it-works2.png"}
              />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              sx={{ height: "490px", position: "relative" }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  //"-ms-transform": "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <Title
                  order={5}
                  fw={400}
                  px="md"
                  size="1.5rem"
                  sx={{
                    maxWidth: "350px",
                  }}
                >
                  💬 See your memory saved on the childs timeline with all their
                  other special moments.
                </Title>
              </Box>
            </Grid.Col>
            {/* 3 */}
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              sx={{ height: "400px", position: "relative" }}
            >
              <Box
                sx={{
                  textAlign: "right",
                  margin: 0,
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  //"-ms-transform": "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <Title
                  order={5}
                  fw={400}
                  px="md"
                  size="1.5rem"
                  sx={{
                    maxWidth: "350px",
                  }}
                >
                  😍 Each morning we send you, along with friends and family
                  that you choose, one of these special moments from your little
                  one&apos;s past.
                </Title>
              </Box>
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={12}
              md={6}
              sx={{
                textAlign: "left",
              }}
            >
              <Image
                alt=""
                width="300"
                height="445"
                src={"/landing/how-it-works3.png"}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </MediaQuery>

      {/* DESIGN FOR MOBILE */}
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Grid.Col
          xs={12}
          sx={{
            textAlign: "center",
            height: "100%",
          }}
        >
          <Grid
            sx={{
              my: 4,
            }}
          >
            {/* 1 */}
            <Grid.Col xs={12} sx={{ my: 2 }}>
              <Box
                sx={{
                  textAlign: "center",
                  margin: 0,
                }}
              >
                <Title
                  order={5}
                  fw={400}
                  px="md"
                  size="1.5rem"
                  sx={{
                    maxWidth: "350px",
                    margin: "0 auto",
                  }}
                >
                  📱 Text the special phone number we provide for each child
                  including pictures and emoji ❤️. Just as easy as texting a
                  friend!
                </Title>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12} sx={{ my: 2 }}>
              <Image
                alt=""
                width="330"
                height="335"
                style={{
                  margin: "0 auto",
                }}
                src={"/landing/how-it-works1.png"}
              />
              <Title order={5} fw={600} size="1rem" color="blue" pl="10px">
                We also support Apple iMessage! 🎉
              </Title>
            </Grid.Col>
            {/* 2 */}
            <Grid.Col xs={12} sx={{ my: 2 }}>
              <Box
                sx={{
                  textAlign: "center",
                  margin: 0,
                }}
              >
                <Title
                  order={5}
                  fw={400}
                  px="md"
                  size="1.5rem"
                  sx={{
                    maxWidth: "350px",
                    margin: "0 auto",
                  }}
                >
                  💬 See your memory saved on the childs timeline with all their
                  other special moments.
                </Title>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12} sx={{ my: 2 }}>
              <Image
                alt=""
                width="300"
                height="508"
                style={{
                  margin: "0 auto",
                }}
                src={"/landing/how-it-works2.png"}
              />
            </Grid.Col>
            {/* 3 */}
            <Grid.Col xs={12} sx={{ my: 2 }}>
              <Box
                sx={{
                  textAlign: "center",
                  margin: 0,
                }}
              >
                <Title
                  order={5}
                  fw={400}
                  px="md"
                  size="1.5rem"
                  sx={{
                    maxWidth: "350px",
                    margin: "0 auto",
                  }}
                >
                  😍 Each morning we send you, along with the friends and family
                  you choose, one of these special moments from your little
                  one&apos;s past.
                </Title>
              </Box>
            </Grid.Col>
            <Grid.Col xs={12} sx={{ my: 2 }}>
              <Image
                alt=""
                width="300"
                height="445"
                style={{
                  margin: "0 auto",
                }}
                src={"/landing/how-it-works3.png"}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </MediaQuery>
    </Grid>
  );
}

export default HowItWorks;
