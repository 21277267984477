import { Divider } from "@mantine/core";
import React from "react";

function PillDivider() {
  return (
    <Divider
      size="xl"
      sx={{
        width: "100px",
        margin: "auto",
        height: "5px !important",
        borderRadius: "20px",
      }}
    />
  );
}

export default PillDivider;
